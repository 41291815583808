/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';

import {
  Helmet,
  langmap,
  flattenToAppURL,
  toReactIntlLang,
} from '@plone/volto/helpers';

import config from '@plone/volto/registry';

import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
});

const LanguageSelector = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  const { settings } = config;

  return settings.isMultilingual ? (
    <div className="language-selector">
      {map(
        settings.supportedLanguages.filter((l) => l !== currentLang),
        (lang) => {
          const translation = find(translations, { language: lang });
          return (
            <>
              <Link
                aria-label={`${intl.formatMessage(
                  messages.switchLanguageTo,
                )} ${langmap[lang].nativeName.toLowerCase()}`}
                className={cx({
                  selected: toReactIntlLang(lang) === currentLang,
                })}
                to={
                  translation ? flattenToAppURL(translation['@id']) : `/${lang}`
                }
                title={langmap[lang].nativeName}
                onClick={() => {
                  props.onClickAction();
                }}
                key={`language-selector-${lang}`}
              >
                {langmap[lang].nativeName}
              </Link>
              <Link
                aria-label="Deutsch"
                className="language-selector-de"
                to="/en/de"
                title="Deutsch"
                onClick={() => {
                  props.onClickAction();
                }}
                key="language-selector-de"
              >
                DEUTSCH
              </Link>
            </>
          );
        },
      )}
    </div>
  ) : (
    <Helmet>
      <html lang={toReactIntlLang(settings.defaultLanguage)} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

export default LanguageSelector;
